<template>
	<div>
		<a-spin :spinning="loading">
		<div class="bg-w pd20" style="min-height: 800px;min-width: 1000px;">
			<div style="width: 800px;">
			<div class="ft20 ftw500 cl-main">短信设置</div>
			 
			
			
			<div v-if="datas!=null" class="mt20">
				<a-form-model
				   ref="ruleForm"
					:model="datas"
					:label-col="{ span: 4}" 
					:wrapper-col="{ span: 20}">	
					
					
					
					<a-alert class="mt20 mb20" message="1.短信目前仅支持这3家,绑定哪一家后要将模版提交到对方平台审核回填模版ID;模版中的变量请按照对应平台的标准更改" type="info" />
					
					<a-form-model-item label="短信设置">
						 <a-radio-group v-model="datas.type">
						 
						      <a-radio value="1">
						        七牛云
						      </a-radio>
						      <a-radio value="2">
						        阿里云
						      </a-radio>
						      <a-radio value="3">
						        腾讯云
						      </a-radio>
						</a-radio-group>
					</a-form-model-item>
					<a-form-model-item label="验证码模版ID" help="请复制短信模版去申请:尊敬的用户您的短信验证码是${code},10分钟内有效!">
						<a-input v-model="datas.captcha_id" style="width: 300px;" placeholder="请输入在对应平台申请的验证码短信模版ID" />
					</a-form-model-item>
					<div v-if="datas.type == 1">
						<a-form-model-item label="ak">
							<a-input v-model="datas.qiniu.ak" style="width: 300px;" placeholder="七牛云的秘钥ak" />
						</a-form-model-item>
						<a-form-model-item label="sk">
							<a-input v-model="datas.qiniu.sk" style="width: 300px;" placeholder="七牛云的秘钥sk" />
						</a-form-model-item>
						
					</div>
					<div v-if="datas.type == 2">
						<a-form-model-item label="AccessKey_id">
							<a-input v-model="datas.ali.access_key_id" style="width: 300px;" placeholder="阿里云的秘钥AccessKey_Id" />
						</a-form-model-item>
						<a-form-model-item label="AccessKey_Secret">
							<a-input v-model="datas.ali.access_key_secret" style="width: 300px;" placeholder="阿里云的秘钥AccessKey_Secret" />
						</a-form-model-item>
						<a-form-model-item label="短信签名" help="需要对方平台审核通过">
							<a-input v-model="datas.ali.sign" style="width: 300px;" placeholder="阿里云的短信签名" />
						</a-form-model-item>
					</div>
					
					<div v-if="datas.type == 3">
						<a-form-model-item label="应用ID">
							<a-input v-model="datas.qcloud.app_id" style="width: 300px;" placeholder="腾讯云的安全秘钥对应的应用ID" />
						</a-form-model-item>
						<a-form-model-item label="SecretId">
							<a-input v-model="datas.qcloud.secret_id" style="width: 300px;" placeholder="腾讯云的安全秘钥SecretId" />
						</a-form-model-item>
						<a-form-model-item label="SecretKey">
							<a-input v-model="datas.qcloud.secret_key" style="width: 300px;" placeholder="腾讯云的安全秘钥SecretKey" />
						</a-form-model-item>
						
						<a-form-model-item label="短信签名">
							<a-input v-model="datas.qcloud.bucket" style="width: 300px;" placeholder="在腾讯云申请的短信签名" />
						</a-form-model-item>
					</div>
				
					
					<a-alert class="mt20 mb20" message="2.短信在线充值及赠送设置" type="info" />
					<a-form-model-item label="赠送短信数">
						 <a-input v-model="datas.give_num" style="width: 200px;"  suffix="条" />
					</a-form-model-item>
					<a-form-model-item label="短信购买价格">
						 <div class="sms-price-tit flex alcenter">
							 <div class="col2 text-center">数量(条)</div>
							 <div class="col2 text-center">价格(元)</div>
						 </div>
						 <div v-for="(item,index) in datas.price" :key="index"  class="mt16 sms-price-rows flex alcenter">
							 <div class="col2 flex alcenter center">
								 <a-input-number style="width:200px;" v-model="datas.price[index].num" :precision="0" :min="0" />
							 </div>
							 <div class="flex col2 alcenter center">
								 <a-input-number style="width:200px;" v-model="datas.price[index].price" :precision="2" :min="0" />
								 <i class="iconfont iconbtn_close ft16 clickAct ml20" @click="delPrice(index)" />
							 </div>
						 </div>
						 <div class="mt10">
							 <a-button @click="addPrice" type="link">+新增套餐</a-button>
						 </div>
					</a-form-model-item>
				
					<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
					      <a-button type="primary"  @click="saveData">
					        保存设置
					      </a-button>
					</a-form-model-item>
				</a-form-model>
			</div>
			</div>
		</div>
		</a-spin>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				loading:false,
				datas:null,
				
			}
		},
		created(){
			this.getData();
		},
		methods:{
			addPrice(){
				let datas = JSON.parse(JSON.stringify(this.datas));
				datas.price.push({
					num:'',
					price:''
				});
				this.datas = datas;
			},
			delPrice(index){
				let price = JSON.parse(JSON.stringify(this.datas.price));
				price.splice(index,1);
				this.datas.price = price;
			},
			getData(){
				this.loading=true;
				this.$http.api('platform/admin/getSettingData',{
					key:'sms'
				}).then(res=>{
					this.loading=false;
					this.datas = res.datas;
					if(this.datas.give_num == undefined){
						this.datas.give_num = 0;
						this.datas.price = new Array;
					}
				}).catch(res=>{
					this.loading=false;
				})
			},
			saveData(){
				this.loading=true;
				this.$http.api('platform/admin/saveSettingData',{
					key:'sms',
					datas:JSON.stringify(this.datas)
				}).then(res=>{
					this.loading=false;
					this.$message.success('保存成功');
				}).catch(res=>{
					this.loading=false;
				})
			}
		}
	}
</script>

<style>
	.sms-price-tit{
		width: 600px;
		height: 40px;
		background: #F9F9F9;
		border: 1px solid #EBEDF5;
		display: flex;
	}
	.sms-price-rows{
		width: 600px;
	}
</style>