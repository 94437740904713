<template>
	<div>
		<a-tabs v-model="type">
			  <a-tab-pane key="setting" tab="短信配置">
				<sms-setting></sms-setting>
				
			  </a-tab-pane>
			  <a-tab-pane key="tpl" tab="模板配置">
				<sms-tpl></sms-tpl>
			  </a-tab-pane>
		</a-tabs>
	</div>
</template>

<script>
	import smsSetting from './components/sms/setting.vue';
	import smsTpl from './components/sms/tpl.vue';
	export default{
		components:{
			smsSetting,
			smsTpl
		},
		data(){
			return {
				type:'setting'
			}
		},
		created(){
			
		},
		methods:{
			callback(e){
				
			}
		}
	}
</script>

<style>
	
</style>